
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'Basis'
})
export default class OndutyAnalysis extends Vue {
    radio = 'asset'

    created () {
      this.radio = this.$route.path.substr(this.$route.path.lastIndexOf('/') + 1)
    }

    handleChange (value: string) {
      this.$router.push({ path: '/asset/' + this.$route.params.assetId + '/' + value })
    }
}
